const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/vps"],
    exact: true,
    component: "Vps",
  },
  {
    path: ["/", "/domain"],
    exact: true,
    component: "Domain",
  },
  {
    path: ["/", "/business"],
    exact: true,
    component: "Business",
  },
  // Company
  {
    path: ["/", "/newsroom"],
    exact: true,
    component: "Newsroom",
  },
  {
    path: ["/", "/partners"],
    exact: true,
    component: "Partners",
  },
  {
    path: ["/", "/events"],
    exact: true,
    component: "Events",
  },
  {
    path: ["/", "/careers"],
    exact: true,
    component: "Careers",
  },
  {
    path: ["/", "/contact"],
    exact: true,
    component: "Contact",
  },
  // Products
  {
    path: ["/", "/cloud"],
    exact: true,
    component: "Cloud",
  },
  {
    path: ["/", "/databases"],
    exact: true,
    component: "Databases",
  },
  {
    path: ["/", "/backup"],
    exact: true,
    component: "Cloud",
  },
  // Solutions
  {
    path: ["/", "/platforms"],
    exact: true,
    component: "Platforms",
  },
  {
    path: ["/", "/hosting"],
    exact: true,
    component: "Hosting",
  },
  {
    path: ["/", "/ecommerce"],
    exact: true,
    component: "Ecommerce",
  },
  // Support
  {
    path: ["/", "/documentation"],
    exact: true,
    component: "Documentation",
  },
  {
    path: ["/", "/tutorials"],
    exact: true,
    component: "Tutorials",
  },
  {
    path: ["/", "/tools"],
    exact: true,
    component: "Tools",
  },
  {
    path: ["/", "/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["/", "/help"],
    exact: true,
    component: "Help",
  },
  {
    path: ["/", "/report-abuse"],
    exact: true,
    component: "ReportAbuse",
  },
];

export default routes;
