import { useState } from "react";
import { Col, Drawer, Row } from "antd";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  Span,
  Large,
} from "./styles";
import { url } from "inspector";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall>
          <Large left="true" to="/vps">
            <Span>{t("Vps")}</Span>
          </Large>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
          <Large left="true" to="/domain">
            <Span>{t("Domain")}</Span>
          </Large>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
          <Large left="true" to="/hosting">
            <Span>{t("Hosting")}</Span>
          </Large>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
          <Large left="true" to="/business">
            <Span>{t("Business")}</Span>
          </Large>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
          <Large left="true" to="/platforms">
            <Span>{t("Platforms")}</Span>
          </Large>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ width: "180px" }} >
          <Span>
            <Button name="login">{t("LogIn")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
          <LanguageSwitchContainer>
            <LanguageSwitch onClick={() => handleChange("en")}>
              <SvgIcon
                src="united-states.svg"
                aria-label="homepage"
                width="30px"
                height="30px"
              />
            </LanguageSwitch>
            <LanguageSwitch onClick={() => handleChange("es")}>
              <SvgIcon
                src="spain.svg"
                aria-label="homepage"
                width="30px"
                height="30px"
              />
            </LanguageSwitch>
          </LanguageSwitchContainer>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
